

@media screen and (max-width:1399px){
.wrapper { font-size:0.7em;}
}
    
    
@media screen and (min-width:1401px){
    .navbar-nav .nav-link, .navbar-light .navbar-nav .nav-link { font-size: 0.9em; }
}
@media screen and (min-width:1025px){
/* .friend-sec .icon-list img {  max-width: 7em;} */
.spl-txt { padding: 0 6em;}
.spl-txt .btn-container { padding: 0.65em 0 0.4em; }
/* .banner .banner-img { height: 44.58em; } */
}

@media screen and (max-width:1199px) and (min-width:768px){
.TwoDadsSec .container { max-width: none; padding-left: 0; padding-right: 0; }
.TwoDadsSec .container .row { margin: 0; }
.TwoDadsSec .container .row .lft-sec + .col { padding-right: 0; }
}

@media screen and (max-width:1024px){
.wrapper { font-size: 0.9em;}
.section { padding: 2em 0; }
.TwoDadsSec { margin:2em 0 4em; }
.banner .banner-txt .banner-subtl,  
.banner .banner-txt .banner-tl { line-height: 1em; }
.banner .banner-txt .banner-subtl + .btn { margin-top: 0.55em; }
.banner .banner-txt .btn-container { padding: 0.8em 0 0.65em; }
}

@media screen and (max-width:1024px) and (min-width:992px){
.navbar-nav .nav-link, 
.navbar-light .navbar-nav .nav-link { font-size: 0.75em; }
}
    
@media screen and (min-width:992px){
.btn-primary {min-width: 10em;}
a.btn-primary { height: 3em;line-height: 1.8em;padding: 0.6em 1.2em;}
.timeLineList > li.image-right .img-sec { padding-left: 5%;  }
.timeLineList > li.image-left .img-sec { padding-right: 5%; }
}

@media screen and (max-width:991px){
.wrapper { font-size: 0.8em;}

.TwoDadsSec .lft-sec { padding-top: 1em; padding-bottom: 1em;}
.follow-sec .box { max-height: 15em; }
.CptOver .Cont-img .reg { height:calc(15em + 0px); }
.CptOver .Cont-img .lg { height: calc(30em + 8px); }
.ContBox.CptBtm { margin: 0.55em; }
.CptBtm .Cont-img { height: 10em;}
.CptBtm .Cont-txt { padding: 1em; }
.bg-shade.type-01:after { bottom: 0; }
.TwoDadsSec .big-img { margin: 0em 0; height: calc(100% + 0em); }
.TwoDadsSec.section:before, .contactsec.section:before, .color-sec.section:before { height: 120%; }
}

@media screen and (min-width:769px){
.our-mission-sec .col { text-align: center; }

}

@media screen and (max-width:768px){
.timeLineList > li.image-right { padding-right: 7%; }
.timeLineList > li.image-left { padding-left: 7%; }
.timeLineList > li .cont-sec { padding-top: 0.5em; }
.timeLineList > li .img-sec { display: inline-block; width:100%; }
.timeLineList > li .img-sec > img { height: auto; width:auto; float: left; max-height:12em; max-width: 12em;; }
.timeLineList > li.image-right .img-sec > img { float: right; }

header { background: #fff; } 
header + .banner, header + .static-banner { padding-top: 12em; }
.banner .banner-txt { position: static; padding:1em; }
.banner .banner-txt .banner-tl { color: #D43FC6; }
.row-grid > [class^="col-lg-"] + [class^="col-lg-"] { margin-top: 2em; }
.navbar-nav > .dropdown > .nav-link:hover, 
.navbar-nav .dropdown-menu .nav-link { padding: .5rem 1rem; }
}

@media screen and (max-width:767px){

}

@media screen and (min-width:600px){
.CptOver .Cont-img .reg, .CptOver .Cont-img .No { height:calc(20em + 0px); }
.CptOver .Cont-img .lg, .CptOver .Cont-img .Yes { height: calc(40em + 5px); }
.data-list { display: table; width:100%; }
/* .data-list .row-grid { display: table-row; }
.data-list .row-grid > div { display: table-cell; vertical-align: middle; }*/
.data-list .row-grid > div .btn-purple { width: 230px; } 
.timeLineList.video > li .img-sec { min-width: 55%; }
.timeLineList.video > li.image-right .img-sec { padding-left: 5%;  }
.timeLineList.video > li.image-left .img-sec { padding-right: 5%;  }
.log-wrap { display: flex; gap:10px; flex-direction: column; align-items: center; justify-content: center; }
.log-wrap > .footer-logo { max-width: calc(80% - 10px); min-width: calc(80% - 10px); }
}

@media screen and (max-width:599px){
.row-grid > div + div { margin-top: 2em; }
.wrapper { font-size: 0.6em;}
.friend-sec .icon-list .rec-arrow { display:none; }
.section, 
.TwoDadsSec .cont-sec, .color-sec .cont-sec { padding-top: 1em; padding-bottom:1em; }
.TwoDadsSec .big-img { margin:0; height: 300px; }
/* .CptBtm .Cont-txt { padding: 0.5em; } */
.CptOver .Cont-txt h3, .CptOver .Cont-txt p { line-height: 1.1em;}
.ContBox.CptBtm { margin: 0.25em; height: auto; }
.CptBtm .Cont-txt h3 { margin-bottom: 0.2em;; }
.contactsecIn .form-row + button { margin-top: 0em; } 
.follow-sec .boxlist.row { margin-right: -5px; margin-left: -5px; }
.follow-sec .boxlist .col { padding-right: 5px; padding-left: 5px; } 
.tab-btn button { margin: 0 0.3em; padding: 0.4em 0.8em; }

.follow-sec .box { max-height: 10em; }
.CptOver .Cont-img .reg { height:calc(10em + 0px); }
.CptOver .Cont-img .lg { height: calc(20em + 4px); }
.CptBtm .Cont-img { height: 8em;}
.our-mission-sec .Box { padding: 1.5em; }
/* .btn-primary { min-width: 8em;height: 2em;line-height: 1.6em;padding: 0.2em 0.5em;} */
.banner .banner-txt .banner-subtl + .btn { margin-top: 0; }
.banner .banner-img { min-height: auto; }
.banner .banner-txt { font-size: 0.8em; }
.video-react { font-size: 6px;}
.banner .banner-txt .banner-subtl, .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 { margin-bottom: 0.25rem; }
.banner .banner-txt .btn-container { padding: 0.4em 0 0.35em;}
.NewsletterSec { padding-top: 0.5em; text-align: center; max-width: none; margin:0 auto; } 
.contactsec { padding:0; }
.tab-btn { margin:0.75em 0; }
.wrapbox .text-cont h2 { padding: 0.4em 0.8em; }
.static-banner { padding: 3em 0;}
header + .static-banner { padding-top: 5em;}
.wrapbox .img-sec,
.wrapbox .img-sec > img { max-height:15em; min-width:15em; }
.carousel.slide { top: auto; padding-bottom: 4em; }
.carousel.slide .cont-sec .main-tl + h3 { margin-top: 0; }
.TwoDadsSec, .color-sec { margin:0; }
.carousel.slide .carousel-indicators { margin-left: 2.5em; margin-right: 2.5em; margin-bottom: 0; bottom: 1.4em;}
.carousel-control-next, .carousel-control-prev { bottom: 0.5em; }
.carousel.slide .carousel-control-next { right: 0; left: auto; }
.carousel.slide .slider-img > img { height: 30em; max-height: none; object-position: top;}
.TwoDadsSec { margin-bottom: 5em; }
header { background: #fff; } 
header + .banner, header + .static-banner { padding-top: 12em; }
.banner .banner-txt { position: static; padding:1em; }
.banner .banner-txt .banner-tl { color: #D43FC6; }

.timeLineList > li:before { width:1em; }
.timeLineList > li .cont-sec {-webkit-box-ordinal-group: 1; -ms-flex-order: 2; order: 2; }
.timeLineList > li .img-sec {-webkit-box-ordinal-group: 1; -ms-flex-order: 1; order: 1; height: 16em; height:auto;}
.timeLineList.video > li .img-sec > .Cont-vid > div { min-height: 20em; }
.timeLineList.video > li .img-sec { max-width: 100%; width:100%}
.timeLineList.video > li.image-right { text-align: left; }

.navbar-expand-lg .navbar-nav .nav-link {padding-right: .5rem; padding-left: .5rem;}
.footerNav { width:100%; text-align: center; }
.footerNav li { padding: 0.2em 0; }
.footer-logo { display: block; max-width: 20em; margin:0 auto 2em; }
span.footer-logo { max-width: 5em;}
.follow-sec { display: none; }
.carousel.slide .carousel-control-next, .carousel.slide .carousel-control-prev { bottom: 0.6em; }
.ContBoxList > .col { width:100%; }

.data-list .row-grid { text-align: center; padding-top: 1.5em; padding-bottom: 0.5em; border:0; }
.data-list .row-grid:before { top: 35px; bottom: 25px;}
.data-list .row-grid > div + div { margin-top: 1em; }
.video-library .rec-arrow { display: none; }
.video-library .rec-pagination { display: block; margin:5px 0 10px }

.log-wrap { display: flex; gap:10px; flex-direction: row;  }
.log-wrap > .footer-logo { width: 40%; max-width: none;}
}

@media screen and (max-width:375px){
.wrapper { font-size: 0.5em;}
.ContBox.CptBtm { margin: 0.05em;}
.wrapbox .text-cont h2 { padding: 0.2em 0.4em; }
}