
html, body, #root, .App, .wrapper { min-height: 100%; display: grid; grid-template-rows: auto 1fr auto; height: 100vh;}
body { font-family: 'Nunito', sans-serif; font-size: 20px; color: #7C7C7C; }
/* body:before { content: ''; position: absolute; left: 0; right:0; height: 100%; background: url(../images/common/ovelay-01.png) repeat-y left top; background-size: 100% auto; } */
.bg-shade { z-index: 3; position: relative; }
.bg-shade + .section { z-index: 2; }
.bg-shade::after,
.bg-shade::before { content: ''; background-repeat: no-repeat; position: absolute; left: 0; top:0; width:100%; height:100%; }
.bg-shade.type-01:after { background-image: url(../images/common/bg-btm.jpg); background-position: center bottom; background-size:100% 100%; z-index: 1; top:auto; bottom: -0em;}
.bg-shade.type-01:before { background-image: url(../images/common/bg-tp.png); background-position: left top; z-index: 2; }
.bg-shade > header { z-index: 4; } 
.bg-shade > header + .banner { z-index: 3; } 
.bg-shade > .banner + .section   { z-index: 2; } 

/*Common CSS*/
.video-react .video-react-poster { background-size: cover; }
p:last-child { margin-bottom: 0; }
.btn-primary { color: #fff; background-color: #DF32BA; border-color: #DF32BA; border-radius: 5px; }
.btn-primary:hover { background-color: #a01093; border-color: #a01093; }
.btn-purple { color: #fff; background-color: #9966cc; border-color: #9966cc; border-radius: 5px; }
.btn-purple:hover { color: #fff; background-color: #9966cc; border-color: #9966cc; }
.btn-primary-o { border-color: #DF32BA; color: #DF32BA; border-radius: 5px; }
.btn-primary-o:hover { border-color: #DF32BA; background: #DF32BA; color: #fff;}
.btn-chat { margin-bottom: 1em; background-color: #29a819; border-color:#29a819; }
.btn-chat:hover { background:#a01093; border-color:#a01093; }
.btn-chat > i { font-size:1.5em; margin-right:0.2em; vertical-align: middle; }
.read-more-button {  display: inline-block; padding: 0.2em 0.4em; cursor: pointer; text-decoration: underline;}
.read-more-button:hover { text-decoration: none; }

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle { background-color: #a01093; border-color: #a01093; }
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(127,10,150,.5); }

.NewsletterSec { max-width: 20em; }
.NewsletterSec + .btn-container { padding-top: 1em; }
.NewsletterSec + .btn-container > .social-ico-list { float: right; }
.social-ico-list { display: inline-block; list-style: none; padding: 0; margin: 0;}
.social-ico-list > li { display: inline-block; padding: 0; margin: 0 0.6em;}
.social-ico-list > li:first-child { margin-left: 0; }
.social-ico-list > li:last-child { margin-right: 0; }
.social-ico-list > li > a { display: inline-block; background-color: #7f0a96; color: #fff; width:2.5em; height:2.5em; border-radius: 2.5em; line-height: 2.5em; text-align:center; }
.social-ico-list > li > a:hover { background:#a01093; }
.social-ico-list > li > a > i { display: inline-block; font-size:1em;  }
.social-ico-list > li > a > i:before { display: block; width:1.6em; height:1.6em; background-position: left top; background-repeat: no-repeat; background-size: 100% auto;  }
.fc-facebook:before { background-image: url(../images/common/icons/fc-fb.png); }    
.fc-twitter:before { background-image: url(../images/common/icons/fc-twitter.png); }    
.fc-instagram:before { background-image: url(../images/common/icons/fc-instagram.png); }  
.fc-youtube:before { background-image: url(../images/common/icons/fc-youtube.png); }
p { margin-bottom: 0.4em; }
.main-tl, 
.sub-tl { color: #7F0A96; text-align: center; font-weight: bold;}
.main-tl { font-size: 2.1em;}
.sub-tl { font-size: 1.8em;}
/*Common CSS*/

header { position: fixed; top:-2px; left: 0; right: 0; z-index: 2;}
header.sticky { background: #fff; box-shadow: 0px 15px 30px rgba(212, 63, 198, 0.2); }
.logo { display:inline-block; max-width:7em; }
.navbar-nav .nav-link, .navbar-light .navbar-nav .nav-link { font-family: 'Nunito', sans-serif; font-style: normal;  font-size: 1em; line-height: 1.5em; color: #7F0A96 !important;}
.navbar-nav .nav-link.active {font-weight: bold; color: #7F0A96 !important;}
.navbar-expand-lg .navbar-nav .nav-link { margin:0.2em 0.12em; }
.navbar-expand-lg .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:hover, 
.navbar-light .navbar-nav > .show  > .nav-link  { background: rgba(223,50,186,0.8); color: #fff !important; }
.navbar-expand-lg .navbar-nav .dropdown-menu { font-size: 1em; padding: 0; }
.navbar-expand-lg .navbar-nav .dropdown-menu .nav-link { margin: 0; }

header + .banner { padding-top: 6.5em; }
.banner { position: relative; text-align: center; z-index: 1 }
.banner .slide { position: relative; }
.banner .banner-img { position: relative; z-index: 1; /*height: 806px;*/ min-height: 20vh; height: auto; background-color: #ddd; }
.banner .banner-img img { object-fit: cover; width:100%; height:100%; }
.banner .banner-img:before, .banner .video-react:before { content: ''; position: absolute; left: 0; top:0; bottom:0; right:0; background: linear-gradient(180deg, rgba(127, 10, 150, 0.91) 0%, rgba(212, 63, 198, 0.87) 100%); opacity: 0.35;
z-index: 2;}
/* .banner .video-react { padding-top:45% !important; } */
.banner .video-react .video-react-big-play-button { font-size: 3em; line-height: 2em; height: 2em; width: 2em; border: 0.06666em solid rgba(255,255,255,1); background: none; border-radius: 2em; opacity: 0.7; }
.banner .video-react-has-started .video-react-video,
.banner .video-react .video-react-big-play-button,
.banner .video-react-control-bar { z-index: 3; }

.banner .banner-txt { position: absolute; z-index: 2; left: 1em; right: 1em; bottom: 1em; text-align: center;  }
.banner .banner-txt .banner-tl { font-size: 2.7em; line-height: 1em; color: #fff;}
.banner .banner-txt .banner-subtl { font-size: 1.3em; line-height: 1em; color: #D43FC6; }
.banner .banner-txt .banner-subtl + .btn { margin-top: 0.45em; }
.banner .banner-txt .btn-container { padding: 1.2em 0 1em; }

.section { padding: 2.5em 0; position: relative;}
.section .section { padding:0; }
.TwoDadsSec.section:before, .contactsec.section:before, .color-sec.section:before { content: ''; position: absolute; left: 0;right: 0; top: 0; height:120%; opacity: 0.75; background: url(../images/common/ovelay-01.png) repeat-y left top; background-size: 100% 100%;}
.TwoDadsSec.section:after, .contactsec.section:after, .color-sec.section:after  { content: '';  }

.fb_iframe_widget,
.fb_iframe_widget > span, .fb_iframe_widget iframe { width:100% !important; }
._5v3q ._1dwg { width: 15.3em; }

img{ display: block; max-width: 100%; height: auto; }


.spl-txt { font-size: 1em; line-height: 1.5em; text-align: center;  color: #7C7C7C; position: relative; -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;}
.spl-txt .btn-container { display: none; position: absolute; left:0; right:0; background:#fff; bottom:0%; padding: 0.95em 0 0.4em;}
.spl-txt .btn-container:first-child,
.spl-txt p + .btn-container { display: inline-block; }
.TwoDadsSec, .color-sec { background: linear-gradient(180deg, #C4139C 0%, #7F0A96 100%); color: #fff; margin:5em 0; position: relative; padding: 0;}
.TwoDadsSec .main-tl, .color-sec .main-tl { color:#fff; text-align: left; }
.TwoDadsSec .cont-sec, .color-sec .cont-sec { padding-top:5em; padding-bottom: 5em; }
.TwoDadsSec .big-img { margin:-5em 0; height: calc(100% + 10em); }
.TwoDadsSec .big-img > img  { width:100%; height: 100%; object-fit: cover;}
.TwoDadsSec .sub-Img { position: absolute; left: calc(50% - 5em); bottom:-4em; width:10em;}
.TwoDadsSec .sub-Img > img { max-width: 100%; height: auto;}

.our-mission-sec .col { padding-top:2em ; }
.our-mission-sec .Box{ background: #FFFFFF; box-shadow: 0px 4px 24px rgba(212, 63, 198, 0.05); padding: 2.75em; height: 100%;}
.our-mission-sec .Box:hover,
.our-mission-sec .Box.active { box-shadow: 0px 15px 30px rgba(212, 63, 198, 0.2); }
.our-mission-sec .Box .icon { display: inline-block; background: #FFEFFE; max-width: 4em; height: 4em; margin-bottom: 0.7em;}
.our-mission-sec .Box .icon > img { display: inline-block; position: relative; top:-1em; right:-1em;     max-width: 4em; height: 4em; object-fit: contain; object-position: center;}
.our-mission-sec .Box .tl { font-weight: 600; font-size: 1.5em; line-height: 1.5em; color: #7F0A96;}
.our-mission-sec .Box p { font-size: 0.9em; line-height: 1.5em; color: #7C7C7C; }
.our-mission-sec .Box p:last-child { margin-bottom: 0; }
.our-mission-sec .btn-container { margin-top: 2em; }

/* .friend-sec .icon-list .rec-arrow:disabled { display:none; } */
.friend-sec .icon-list .rec-pagination { display:none; }
.cara-item { width:100%; }
.friend-sec .icon-list > div { padding:0.2em; }
.friend-sec .icon-list .cara-item > a { display: inline-block; width:100%; }
.friend-sec .icon-list .cara-item > a > img { display: block; width:calc(100% - 0.2em); border: 4px solid #FFF4FE; box-sizing: border-box; }
.friend-sec .icon-list .cara-item > a:hover > img { border-color: #FFF4FE; }
.shadowBox { background: #FFFFFF; box-shadow: 0px 15px 30px rgba(212, 63, 198, 0.2); }
.bg-light-pink,
.follow-sec {background: #FFF4FE; }
.follow-sec .boxlist { padding-top:1em; }
.follow-sec .boxlist .col { padding-right: 0.25em;  padding-left: 0.25em; }
.follow-sec .box { max-height: 33.5em; overflow: hidden; height: 100%; }
.follow-sec .box.tweetbox > div,
.follow-sec .box.tweetbox > div > div, .follow-sec .box .facebookbox > div { height: 100%; }
.follow-sec .box img { height:100%; object-fit: cover; width: 100%; object-position: top center; }
.follow-sec .box.InstaGrambox iframe { border: 0 !important; border-radius: 0 !important; width: 100% !important; min-width: inherit !important;}
.follow-sec .box.InstaGrambox .fixedBox { position:absolute; top:0; padding:10px; width:100%; z-index:3; height:45px; background-color: #ffffff;   }
.follow-sec .box.InstaGrambox { overflow-y:scroll !important; position:relative; }
.timeline-Header-title {
    font-size: 21px;
    font-weight: 300;
    line-height: 24px;
    color: #292F33;
    padding: 10px 0px 2px 20px;
}
.timeline-Header-byline {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #657786;
}


.tab-btn { margin: 2em 0; }
.tab-btn button { background: #FFFFFF; border: 1px solid #7F0A96; box-sizing: border-box;border-radius: 0.25em; margin: 0 0.6em; padding: 0.6em 1.2em; text-transform: capitalize; font-size:1.2em; }
.tab-btn button:first-child,
.tab-btn button:active, 
.tab-btn button:foucs { background: #7F0A96;  }

.tab-btn.type-primary button { border-color: transparent;  box-shadow: 0px 5px 24px rgba(106, 1, 96, 0.1); font-size: 1.2em; line-height: 1.5em; text-align: center; color: #7F0A96; }
.tab-btn.type-primary button:active, 
.tab-btn.type-primary button:focus { border-color: transparent; background: #7F0A96; color: #fff;}

.ContBox { position: relative; display: block; }
.ContBox .Cont-vid,
.ContBox .Cont-vid > div { height:12em; }
.ContBox .Cont-img { width: 100%; overflow: hidden; position: relative; z-index: 1; }
.ContBox .Cont-img > img { width:100%; height:100%; object-fit: cover; object-position: center; height:calc(20em + 0px); position: relative; z-index: 1;  /* Animation */}
.ContBox .Cont-txt { padding:0.5em;}
.CptOver .Cont-img:before { content: ''; position: absolute; left: 0; right: 0; bottom: 0px; top:50%; background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 84.38%); z-index: 2; }
.CptOver .Cont-txt { position: absolute; left: 0; bottom: 0; right: 0; z-index: 2; }
.CptOver .Cont-txt h3 { font-weight: bold; font-size: 1.2em; color: #FFFFFF; }
.CptOver .Cont-txt > p { max-height: 3em; overflow: hidden; }
.CptOver .Cont-txt div, 
.CptOver .Cont-txt p { font-size: 0.9em; color: #E3B4E0; 
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;  
overflow: hidden; }
.CptOver .Cont-txt p figure, .CptOver .Cont-txt p ol { display: none; }
.ContBox.CptBtm { margin:0.75em; height: calc(100% - 1.5em); border-radius: 0.5em; filter: drop-shadow(0px 4px 30px rgba(212, 63, 198, 0.2)); overflow: hidden;}
.CptBtm .Cont-img { height: 15em; overflow: hidden; position: relative; }
.CptBtm .Cont-img:before { content: ''; position: absolute; width:2em; height:2em; background: url(../images/common/jam_play.png) no-repeat left top; background-size: contain; left:calc(50% - 1em); top: calc(50% - 1em); z-index: 2; }
.CptBtm .Cont-img:after { content: ''; position: absolute; background: rgba(0, 0, 0, 0.3); left:0; top: 0; width:100%; height:100%; z-index: 1; }
.CptBtm .Cont-txt { background: #fff; padding:1.5em; height: calc(100% - 12em); }
.CptBtm .Cont-txt h3 { font-size: 1.4em; color: #7F0A96; font-weight: bold; }
.CptBtm .Cont-txt p { font-size: 0.9em; color: #7C7C7C;  }
.CptBtm .Cont-txt p:last-child { margin-bottom: 0;  }

.mediaprojsec {background: #F2E8FB;}

.bg-pink-grad,
.contactsec { background: linear-gradient(180deg, #A01093 -5.9%, #7F0A96 100%); }
.contactsecIn { background: #fff; }
.contactsecIn .formSec { padding:2em; background-color: #fff; }
.contactsecIn .formSec .main-tl { text-align: left; }
.contactsecIn .formSec p a { color: #7C7C7C; }
.no-gutter.row > .col { padding: 0; }
.contactsecIn .form-control { background: rgba(255, 255, 255, 0.2); border: 1px solid #C773C1; box-sizing: border-box;
border-radius: 2px; height: 2.5em; }
.contactsecIn textarea.form-control { height: 4.5em; }
.contactsecIn .form-row + button { margin-top: 0em; background: #7F0A96; border-color: #7F0A96; }
.contactsecIn .form-row + button:hover {  background:#a01093; border-color:#a01093; }
.MapImg { height:100%; }
.MapImg img { height:100%; width:100%; object-fit: cover; }

.ows-str-sec .section.HighlightSec { padding: 0 0 1.5em; }
.ows-str-sec .video-react .video-react-big-play-button { background: url(../images/common/jam_play.png) no-repeat center; width:2.5em; height:3em; margin-top: -0.75em; margin-left: -1.5em; border:0; background-size: contain; }
.ows-str-sec .video-react .video-react-big-play-button:before { display: none; }

header + .static-banner { padding-top: 6em; z-index: 1;}
.static-banner { position: relative; color:#fff; padding: 3em 0; }
.static-banner .container { position: relative; z-index: 3; }
.static-banner:before { content: ''; z-index: 1; position: absolute; left:0; top:0; width:100%; height:100%; background: linear-gradient(180deg, rgba(127, 10, 150, 0.91) 0%, rgba(212, 63, 198, 0.87) 100%); opacity: 0.35; }

.carousel.slide { top:-2.5em; padding-bottom:3em; }
.carousel.slide .cont-sec .main-tl { color: #FFF146; } 
.carousel.slide .cont-sec .main-tl + h3 { margin-top: 2em; } 
.carousel.slide .cont-sec h3 {  color: #fff; } 
.carousel.slide .cont-sec p {font-size:0.9em; line-height: 1.5em; }
.carousel.slide .slider-img > img { width:100%; max-height: 28.35em; object-fit: cover; }
.carousel.slide .carousel-indicators { margin-left:50%; margin-right: -15px; margin-bottom: 0; bottom: -0em;}
.carousel.slide .carousel-indicators li { width:1em; height: 1em; border-radius:1em; background: linear-gradient(0deg, #AE4EA5, #AE4EA5), linear-gradient(0deg, #EBD8E9, #EBD8E9), #EBD8E9; border:0; margin-right: 0.4em; margin-left: 0.4em;}
.carousel.slide .carousel-indicators li.active { background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #EBD8E9, #EBD8E9), #EBD8E9; }
.carousel.slide .carousel-control-next, 
.carousel.slide .carousel-control-prev { width:2.5em; height:2.5em; border: 1px solid #FFFFFF; box-sizing: border-box; border-radius: 5px; top: auto; bottom:-0.4em; }
.carousel.slide .carousel-control-next { right: auto; left: 3.5em;}

.static-banner:after{ content: ''; z-index: 2; position: absolute; left:0; top:0; width:100%; height:100%;  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 76.23%); opacity: 0.6;}
.static-banner .main-tl { color: #FFFFFF; text-align: left;}
.static-banner p { color: #FFFFFF;  max-width:25em; }

.wrapbox { position: relative; display: block; height: 100%; }
.wrapbox .img-sec { position: relative; z-index: 1; max-height: 25em; overflow: hidden; }
.wrapbox .img-sec:before { content: ''; position: absolute; left: 0; top:0; width: 100%; height:100%; background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(80, 19, 158, 0.6) 100%); opacity: 0.35;  z-index: 2;}
.wrapbox .img-sec > img { position: relative; z-index: 1;  width:100%; height:100%; object-fit: cover; object-position: center center; min-height: 25em;}
.wrapbox .text-cont { position: absolute; left: 0; right: 0; bottom: 0; z-index: 1; }
.wrapbox .text-cont h2 { font-weight: bold; font-size: 1.8em; line-height: 1.5em; color: #FFFFFF; padding: 1em 2em; margin-bottom: 0; }
.static-banner + .no-gutters + .blogsec .main-tl { display: none; }

.infoBox { padding:1em; }
.infoBox p { font-size: 1em; color: #7F0A96; }
.infoBox p a { color: #7F0A96; }

.timeLineSec { background: #F4E4F7; }
.timeLineSec::before { content: ''; position: absolute; left: 0; top: 0; bottom:0; right:0; background: url(../images/common/timline-pattern.png) repeat left top; background-size: contain; opacity: 0.2; display: none;}
.timeLineSec::before { content: ''; position: absolute; left: 0; right: 0; top: 0; bottom:0; opacity: 0.2; background: url(/static/media/ovelay-01.f19cf8ec.png) repeat-y left top;  display: block; background-size: 100% auto;}
.timeLineList { display: inline-block; width:100%; list-style: none; padding: 0; margin:2em 0; position: relative;}
.timeLineList:before { content: ''; width:0.2em; height: 100%; position: absolute; left:calc(50% - 0.1em); top:0; border-left:0.09em dashed #7F0A96; }
.timeLineList.video:before { display: none; }
.timeLineList > li { width:50%; clear: both; position: relative; }
.timeLineList > li > div { width:100%}
.timeLineList > li > div > div { width:100%}
.timeLineList > li .cont-sec { width:100%; }
.timeLineList.video > li { width:100%; clear: both; display: inline-block; position: relative; }
.timeLineList > li + li { margin-top: -2%; }
.timeLineList.video > li + li {  margin-top:5em; }
.timeLineList > li:before { content: ''; width:2em; height: 0.06em; position: absolute; top:calc(50% - 0.25em); right:0; background:#7F0A96 ; }
.timeLineList.video > li:before { display: none; }
.timeLineList > li.image-left:before { right:auto; left:0; background:#7F0A96 ; }
.timeLineList > li.image-right { padding-right: 4%; text-align: right; }
.timeLineList.video > li.image-right { padding-right: 0%; }
.timeLineList > li.image-left { float: right; padding-left: 4%; }
.timeLineList.video > li.image-left { float: right; padding-left: 0%; }
.timeLineList > li .row { align-items: center; }
.timeLineList > li .cont-sec p { font-size:1.1em; color: #6C6C6C; }
.timeLineList.video > li .cont-sec p { font-size: 1.4em; }
.timeLineList > li .cont-sec p + p,
.timeLineList > li .cont-sec div + p { margin-top:1em; }
.timeLineList > li .cont-sec p.year-txt { font-weight: 700; }
.timeLineList.video > li .cont-sec h3 { font-weight: 700; color: #7C7C7C}
.timeLineList.video > li .cont-sec h3 a { text-decoration: none; color: #7C7C7C; }
.timeLineList.video > li .cont-sec h3 a:hover { text-decoration: underline; }
.timeLineList > li.image-right .img-sec {-webkit-box-ordinal-group: 1; -ms-flex-order: 2; order: 2; }
.timeLineList > li.image-right .cont-sec {-webkit-box-ordinal-group: 1; -ms-flex-order: 1; order: 1; margin-left: auto;}
.timeLineList > li .img-sec { width:13em; width:100%; max-width: 14em; min-width:14em; height:13em; height:auto; }
.timeLineList.video > li .img-sec { max-width: 60%; min-width:60%;}
.timeLineList > li .img-sec > img { object-fit: contain; width:100%; height:100%; object-position: center; padding:0.0em 0; }
.timeLineList.video > li .img-sec > .Cont-vid > div { height:30em;  }

.video-container > div { height: 30em; }

/*Footer Css*/
footer { background-color: #000; font-size: 1em; padding: 2em 0 1em;}
footer a { font-size: 1em; font-size: 0.8em; font-weight:bold; color: #7C7C7C;}
footer a:hover { color: #fff; text-decoration: none;}
.footerNav { display: inline-block; list-style: none; padding: 0; margin:0}
.footerNav li a {white-space: nowrap; display: inline-block; padding: 0.4em 0.8em; line-height: 1.5em;; }
.NewsletterSec .input-group-append .btn-primary { background:#7f0a96; border-color:#7f0a96; }
.NewsletterSec .input-group-append .btn-primary:hover { background:#a01093; border-color:#a01093; }

.footer_copyright { border-top:1px solid rgba(255,255,255,0.2); padding: 1em 0 0;  margin-top: 2em;}
.footer_copyright p { margin-bottom: 0; font-size: 0.8em; }
.footer_copyright p a { font-size: 1em; font-weight: 400;}
/*Footer Css*/


.wrapbox .img-sec, .ContBox .Cont-img, .friend-sec .icon-list .cara-item > a { overflow: hidden; position: relative; z-index: 1; }
.wrapbox .img-sec > img, .ContBox .Cont-img > img, .friend-sec .icon-list .cara-item > a > img  { transition: transform .5s; }
.wrapbox:hover .img-sec > img, .ContBox:hover .Cont-img > img, .friend-sec .icon-list .cara-item > a:hover > img { transform: scale(1.1); }

.ContBoxList > .col-lg-4 > div { height: 100%; }
.ContBoxList + .btn-container { margin:2em 0; }
.event-sec { z-index: 1; }
.data-list { padding: 0 15px; }
.data-list .row-grid { padding-top: 0.5em; padding-bottom: .5em; transition: transform .5s; background: #fff; position: relative;}
.data-list .row-grid:before { content: ''; position: absolute; left: 0; top: 0; right: 0; bottom:-1px; border:1px solid #ddd; z-index: 1;}
.data-list .row-grid > div { z-index: 2; }
.data-list .row-grid:hover { transform: scale(1.0075);}


.video-library.diff { background: #F4E4F7; }
/* .video-library .rec-slider-container { margin: 0 -0.75em; } */
.video-library .rec-carousel,
.video-library .cara-item, .video-library .cara-item .ContBox.CptBtm { height:26em !important; }
.video-library .cara-item .ContBox.CptBtm { height:calc(26em - 1.5em) !important; max-height:calc(26em - 1.5em) !important; }
.video-library .rec-pagination { display: none; }
.video-library .ContBox.CptBtm { margin: 0.75em; border-radius: 0.2em; filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.2));}
.video-library .CptBtm .Cont-txt h3 { display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; }
.video-library .CptBtm .Cont-txt p { display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical;  overflow: hidden; }
.video-library .rec-arrow { background: none !important; border: 0; box-shadow: none !important; font-size: 4em; outline: 0 !important; position: absolute;}
.video-library .rec-arrow.rec-arrow-left { left:calc(0% - 25px)}
.video-library .rec-arrow.rec-arrow-right { right:calc(0% - 25px)}
.video-library .rec-arrow:hover { background: none !important; color: #7F0A96 !important; box-shadow: none !important; }

.tncdata div p { font-size:1.2em; }
.tncdata div p a { color: #7C7C7C; }
.tncdata div + h4 { margin-top: 2em; font-weight: 700; }

.img-thumbnail { display: inline-block; width:calc(100% - 2em); margin:1em; border: 0; padding: 0; height: calc(100% - 2.45em);}
.img-thumbnail > img { width:100%; height: 100%; object-fit: cover; object-position: center;}
#ac-wrapper { position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.9); z-index: 1001; display: flex; align-items: center; justify-content: center; }
#ac-wrapper a { display: inline-block; background: rgba(0, 0, 0, 0.9); padding: 2em;}
.form-control > span > input { border: 0; outline: 0;}


.modernshowimg {
    width:100%;
    padding: 0 0 40px 0;
    margin: 0;
}

.d-block {
    display: block;
}

.our-social-feeds-sec {}
.our-social-feeds-sec > iframe { border:0; width: 100%; height: 375px; }

.bookConsultation-sec > iframe { border:0; width: 100%; height: 675px; }

.partner-box-wrap { display: flex; flex-wrap: wrap; margin: -15px 15px; }
.partner-box-wrap .partner-box { display: flex; flex-direction: column; position: relative; width: calc(25% - 15px - 15px); overflow: hidden; margin: 15px; border: 1px solid #df32ba; background-color: #fff; }
.partner-box-wrap .partner-box .img-sec { display: flex;  position: relative; }
.partner-box-wrap .partner-box .text-sec { padding: 15px;  color: #fff; overflow: auto; text-align: center;}
.partner-box-wrap .partner-box .text-sec h2 { color: #7F0A96; font-weight: bold; font-size: 1.2rem;}
.partner-box-wrap .partner-box .text-sec .txt-cont { margin-bottom: calc(15px + 54px); }
.partner-box-wrap .partner-box .text-sec .txt-cont p { font-size: 0.9rem; color: #7C7C7C; }
.partner-box-wrap .partner-box .text-sec .btn-view { display: inline-block; text-decoration: none; font-size: 1rem; position: absolute; left: calc(50% - calc(180px / 2)); bottom: 15px; min-width: 180px;}

.clinic-partner-page .bg-shade > div { position: relative; z-index: 2; }

@media (max-width:599px) {
    .partner-box-wrap { max-width:calc(390px - 15px - 15px); overflow: scroll; flex-wrap: nowrap; }
    .partner-box-wrap .partner-box { min-width:calc(300px - 15px - 15px) }
}


#hubspotForm { margin-bottom: 30px ;}